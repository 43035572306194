<template>
  <div>
    <h2 class="text-center m-b-10">
      {{curTaskInfo.assetName}}({{curTaskInfo.resourceCode}}) 视频播出方案
    </h2>
    <div>
      <Row
        :gutter="8"
        class="m-b-10"
      >
        <i-col span="4">
          <h3>播出日期：{{curTaskInfo.publishDay}}</h3>
        </i-col>
        <i-col
          span="4"
          class="text-right"
        >
          <h3>轮播时长：<span>{{loopDuration}}分钟</span></h3>
        </i-col>
        <i-col
          span="16"
          class="text-right"
        >
          <!--状态为草稿和审批未通过显示以下按钮-->
          <template v-if="[1,3].includes(curTaskInfo.status)">
            <!-- <Button
              type="primary"
              size="small"
              class="m-r-5"
              @click="handleAddProgram"
            >添加节目</Button> -->
            <Button
              type="success"
              size="small"
              class="m-r-5"
              @click="handleRebuild"
            >重新生成</Button>
          </template>
          <!-- <Button
            type="success"
            size="small"
            @click="handleExport"
          >导出Excel</Button> -->
        </i-col>
      </Row>

      <Table
        stripe
        size="small"
        :data="list"
        :columns="dataColumns"
        :loading="tableLoading"
      ></Table>
    </div>
    <set-duration-modal
      ref="setDuration"
      :operationType="2"
      :programId="curProgramId"
      :sumDuration="sumDuration"
      :successFun="initData"
    ></set-duration-modal>

    <Modal
      v-model="jumpModal"
      width="400"
      @on-ok="handleJump"
    >
      <p
        slot="header"
        class="text-center"
      >序号移动操作</p>
      <div>
        <div class="m-b-10">请输入目标序号</div>
        <InputNumber
          size="small"
          style="width:100%"
          :max="9999"
          :min="1"
          v-model="jumpSortNum"
        ></InputNumber>
      </div>
    </Modal>
    <Modal
      v-model="insertModal"
      width="800"
      footer-hide
    >
      <p
        slot="header"
        class="text-center"
      >添加内容</p>
      <div>
        <Row
          :gutter="8"
          class="m-b-10"
        >
          <i-col
            span="22"
            class="text-right"
          >
            <i-input
              v-model="materialQuery.queryString"
              size="small"
              placeholder="关键字：视频名称"
            ></i-input>
          </i-col>
          <i-col span="2">
            <Button
              type="primary"
              icon="ios-search"
              size="small"
              @click="handleSearchContent"
            >搜索</Button>
          </i-col>
        </Row>
        <Table
          stripe
          size="small"
          :data="videolist"
          :columns="contentColumns"
        ></Table>
        <div class="paging_style">
          <Page
            size="small"
            :total="materialTotal"
            :page-size="materialQuery.pageSize"
            show-total
            show-elevator
            :current="materialQuery.pageNum"
            @on-change="handleChangePage"
          ></Page>
        </div>
      </div>
    </Modal>
    <Modal
      v-model="swapModal"
      width="800"
    >
      <p
        slot="header"
        class="text-center"
      >替换节目素材</p>
      <div>
        <Row
          :gutter="8"
          class="m-b-10"
        >
          <i-col span="4">全部替换</i-col>
          <i-col span="8">
            <i-switch
              v-model="allReplace"
              class="m-r-10"
            >
              <span slot="open">是</span>
              <span slot="close">否</span>
            </i-switch>
          </i-col>
          <i-col span="12">
            <span class="text-orange">注：选择全部替换，则会替换掉当前列表中的该节目的所有素材，反之，则只替换当前项的节目素材。</span>
          </i-col>
        </Row>
        <p class="p-b-10 text-orange">注：只能选择和当前节目素材相同播放时长的替换节目素材</p>
        <Table
          stripe
          size="small"
          :data="videolist"
          :columns="swapColumns"
        ></Table>
      </div>
    </Modal>
    <video-show
      ref="videoShow"
      domFlag="playList"
    ></video-show>
  </div>
</template>

<script>
import { sysMixins } from '@/assets/mixins/sys'
import { eleArrangeMixins } from '../../mixins'
import { listProgramDetails, updateProgramOrder, addProgramDetail, deleteProgramDetail, replaceProgramDetail } from '@/api/inventory/editing'
// import { listVideoFiles } from '@/api/inventory/video'

import VideoShow from '@/components/eleScreen/components/VideoShow.vue'
import SetDurationModal from '../common/SetDurationModal.vue'

export default {
  mixins: [sysMixins, eleArrangeMixins],
  components: {
    VideoShow,
    SetDurationModal
  },
  data () {
    return ({
      tableLoading: false,
      dataColumns: [
        { title: '#', key: 'index', width: 80 },
        {
          title: '客户',
          key: 'advertiserName',
          align: 'center',
          render: (h, params) => {
            return h('span', params.row.advertiserName || '-')
          }
        },
        {
          title: '品牌',
          key: 'brandName',
          align: 'center',
          render: (h, params) => {
            return h('span', params.row.brandName || '-')
          }
        },
        {
          title: '行业',
          key: 'industryName',
          align: 'center',
          render: (h, params) => {
            return h('span', params.row.industryName || '-')
          }
        },
        {
          title: '类型',
          key: 'type',
          align: 'center',
          render: (h, params) => {
            switch (params.row.type) {
              case 1:
                return h('Tag', {
                  props: { color: 'primary' }
                }, '商业')
              case 2:
                return h('Tag', {
                  props: { color: 'success' }
                }, '公益')
              case 3:
                return h('Tag', {
                  props: { color: 'blue' }
                }, '小微')
              case 4:
                return h('Tag', '节目')
              default:
                return h('Tag', {
                  props: { color: 'error' }
                }, '未知')
            }
          }
        },
        {
          title: '投放视频',
          render: (h, params) => {
            return h('a', {
              on: {
                click: () => {
                  const postData = {
                    fileName: params.row.fileName,
                    filePath: params.row.fileKey
                  }
                  this.$refs.videoShow.show(postData)
                }
              }
            }, params.row.fileName)
          }
        },
        // {
        //   title: '播放时间',
        //   align: 'center',
        //   render: (h, params) => {
        //     return h('span', `${params.row.startTime} - ${params.row.endTime}`)
        //   }
        // },
        { title: '时长（秒）', key: 'duration', align: 'center' },
        {
          title: '操作',
          align: 'center',
          width: 140,
          render: (h, params) => {
            const html = []
            if ([1, 3].includes(this.curTaskInfo.status)) {
              // 草稿和审批未通过显示
              html.push(
                h('Icon', {
                  props: { type: 'md-arrow-forward', size: 20, color: '#2d8cf0' },
                  attrs: { title: '移动' },
                  style: { cursor: 'pointer' },
                  on: {
                    click: () => {
                      this.curProgramDetailId = params.row.id
                      this.currentNum = params.row.index
                      this.jumpSortNum = params.row.index
                      this.jumpModal = true
                    }
                  }
                }),
                h('Icon', {
                  props: { type: 'md-add', size: 20, color: '#19be6b' },
                  attrs: { title: '新增' },
                  style: { 'margin-left': '5px', cursor: 'pointer' },
                  on: {
                    click: () => {
                      this.getContentData()
                      this.curProgramDetailId = params.row.id
                      this.currentNum = params.row.index
                      this.insertModal = true
                    }
                  }
                })
              )
              // 节目内容可更换，删除
              if (params.row.type === 4) {
                html.push(
                  h('Icon', {
                    props: { type: 'md-swap', size: 20, color: '#2d8cf0' },
                    attrs: { title: '更换' },
                    style: { 'margin-left': '5px', cursor: 'pointer' },
                    on: {
                      click: () => {
                        this.handleSwap(params.row)
                      }
                    }
                  }),
                  h('Icon', {
                    props: { type: 'ios-trash-outline', size: 20, color: '#ef4f4f' },
                    attrs: { title: '删除' },
                    style: { 'margin-left': '5px', cursor: 'pointer' },
                    on: {
                      click: () => {
                        this.handleDelete(params.row)
                      }
                    }
                  })
                )
              }
              return h('div', html)
            } else {
              return h('span', '/')
            }
          }
        }
      ],
      list: [],
      // totalDuration: 0,
      sumDuration: 0,
      curProgramId: null,
      curProgramDetailId: null,
      jumpModal: false,
      jumpSortNum: 1,
      currentNum: 0,
      insertModal: false,
      contentList: [],
      contentTotal: 0,
      contentColumns: [
        { title: '视频名称', key: 'fileName' },
        { title: '时长(秒)', key: 'duration', align: 'center' },
        {
          title: '操作',
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h('a', {
                on: {
                  click: () => {
                    this.handleInsert(params.row)
                  }
                }
              }, '添加')
            ])
          }
        }
      ],
      swapModal: false,
      swapProgramIndex: 0,
      allReplace: false,
      swapColumns: [
        { title: '视频名称', key: 'fileName' },
        { title: '时长(秒)', key: 'duration', align: 'center' },
        {
          title: '操作',
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h('a', {
                on: {
                  click: () => {
                    this.handleSwapConfirm(params.row)
                  }
                }
              }, '替换')
            ])
          }
        }
      ]
    })
  },
  computed: {
    curTaskInfo () {
      return this.$store.state.electronic.curTaskInfo
    },
    userInfo () {
      return this.$store.getters.token.userInfo
    },
    loopDuration () {
      // const m = this.sumDuration / 60
      // return parseFloat(m.toPrecision(6)) || 0
      return (this.sumDuration / 60).toFixed(2) || 0
    }
  },
  created () {
    this.setLinkTagArray({ key: 'electronicPlayList', value: '投放计划列表', actived: true })
    this.setActivedTagName('electronicPlayList')

    this.initData()
  },
  methods: {
    initData () {
      this.list = []
      this.tableLoading = true
      listProgramDetails({ programId: this.curTaskInfo.id }).then(res => {
        this.tableLoading = false
        if (res && !res.errcode) {
          this.list = res.details
          this.sumDuration = res.sumDuration
        } else {
          this.list = []
          this.sumDuration = 0
        }
      })
    },
    /**
     * 移动序号
     */
    handleJump () {
      if (this.currentNum === this.jumpSortNum) {
        this.$Notice.info({ desc: '当前序号未发生改变' })
        return false
      }
      this.$Modal.confirm({
        title: '操作提示',
        content: '确定要变更当年排序?',
        onOk: () => {
          const postData = {
            from: this.currentNum,
            programDetailId: this.curProgramDetailId,
            programId: this.curTaskInfo.id,
            to: this.jumpSortNum
          }
          updateProgramOrder(postData).then(res => {
            if (res && res.errcode === 0) {
              this.initData()
              this.$Notice.success({ desc: '操作成功' })
            }
          })
        }
      })
    },
    handleInsert (params) {
      this.$Modal.confirm({
        title: '操作提示',
        content: '确定要添加该内容？',
        onOk: () => {
          const postData = {
            duration: params.duration,
            fileKey: params.id,
            fileName: params.fileName,
            index: this.currentNum,
            programId: this.curTaskInfo.id,
            type: 4
          }
          addProgramDetail(postData).then(res => {
            this.insertModal = false
            if (res && res.errcode === 0) {
              this.initData()
              this.$Notice.success({ desc: '操作成功' })
            }
          })
        }
      })
    },
    /**
     * 重新生成明细
     */
    handleRebuild () {
      this.curProgramId = this.curTaskInfo.id
      this.$refs.setDuration.initShow()
    },
    getContentData () {
      this.videolist = []
      this.materialQuery.pageNum = 1
      this.materialQuery.queryString = ''
      this.materialQuery.duration = null
      this.getContentMaterialData()
    },
    handleSearchContent () {
      this.materialQuery.pageNum = 1
      this.getContentMaterialData()
    },
    handleDelete (params) {
      this.$Modal.confirm({
        title: '操作提示',
        content: '确定要删除当前数据?',
        onOk: () => {
          const postData = {
            index: params.index,
            programDetailId: params.id,
            programId: this.curTaskInfo.id
          }
          deleteProgramDetail(postData).then(res => {
            if (res && res.errcode === 0) {
              this.initData()
              this.$Notice.success({ desc: '操作成功' })
            }
          })
        }
      })
    },
    /**
     * 更换节目素材
     */
    handleSwap (params) {
      this.videolist = []
      this.materialQuery.pageNum = 1
      this.materialQuery.queryString = ''
      this.materialQuery.duration = params.duration
      this.swapProgramIndex = params.index
      this.getContentMaterialData()
      this.swapModal = true
    },
    handleSwapConfirm (params) {
      this.$Modal.confirm({
        title: '操作提示',
        content: '确定要替换当前节目素材？',
        onOk: () => {
          const postData = {
            allReplace: Number(this.allReplace),
            duration: params.duration,
            fileId: params.id,
            fileName: params.fileName,
            index: this.swapProgramIndex,
            programId: this.curTaskInfo.id
          }
          replaceProgramDetail(postData).then(res => {
            this.swapModal = false
            if (res && res.errcode === 0) {
              this.initData()
              this.$Notice.success({ desc: '操作成功' })
            }
          })
        }
      })
    },
    /**
     * 导出Excel
     */
    handleExport () {

    }
  }
}
</script>
