<template>
  <div>
    <Modal
      v-model="setDurationModal"
      :mask-closable="false"
      width="600"
    >
      <p
        slot="header"
        class="text-center"
      >生成编播方案明细</p>
      <div>

        <Row
          :gutter="8"
          class="m-b-10"
        >
          <i-col span="4">轮播时长</i-col>
          <i-col span="8">
            <InputNumber
              style="width:120px"
              size="small"
              :max="1440"
              :min="0"
              v-model="duration"
            ></InputNumber><span class="m-l-5 m-r-10">分钟</span>
          </i-col>
          <i-col span="12">
            <span class="text-orange">注：最大时长为1440分钟，即24小时。</span>
          </i-col>
        </Row>
        <template v-if="operationType===1">
          <Row
            :gutter="8"
            class="m-b-10"
          >
            <i-col span="4">指定节目素材</i-col>
            <i-col span="8">
              <i-switch
                v-model="isCustomMaterial"
                class="m-r-10"
                @on-change="changeIsCustomMaterial"
              >
                <span slot="open">是</span>
                <span slot="close">否</span>
              </i-switch>
            </i-col>
            <i-col span="12">
              <span class="text-orange">注：未选择指定节目素材，系统将自动生成。</span>
            </i-col>
          </Row>
          <div v-show="isCustomMaterial">
            <p class="m-b-10">选择节目素材</p>
            <div class="m-b-10">
              <Tag
                v-for="item in selectedMaterialArray"
                :key="item.id"
              >{{item.fileName}}</Tag>
            </div>
            <Table
              stripe
              size="small"
              :data="videolist"
              :columns="dataColumns"
              @on-selection-change="handleSelection"
            ></Table>
            <div class="paging_style">
              <Page
                size="small"
                :total="materialTotal"
                :page-size="materialQuery.pageSize"
                show-total
                :current="materialQuery.pageNum"
                @on-change="handleChangePage"
              ></Page>
            </div>
          </div>
        </template>
      </div>
      <div slot="footer">
        <Button
          type="text"
          @click="setDurationModal=false"
        >取消</Button>
        <Button
          type="primary"
          @click="handleConfirm"
        >确定</Button>
      </div>
    </Modal>
    <video-show
      ref="videoShow"
      domFlag="selectedMaterial"
    ></video-show>
  </div>
</template>

<script>
import VideoShow from '@/components/eleScreen/components/VideoShow.vue'
import { commonMixins } from '@/assets/mixins/common'
import { eleArrangeMixins } from '../../mixins'
import { createProgramDetails, recreateProgramDetails } from '@/api/inventory/editing'
export default {
  mixins: [commonMixins, eleArrangeMixins],
  components: {
    VideoShow
  },
  props: {
    operationType: {
      type: Number,
      default: 1 // 1：初始化，2：重新生成
    },
    sumDuration: {
      type: Number,
      default: 60
    },
    programId: Number,
    successFun: Function
  },
  data () {
    return {
      setDurationModal: false,
      selectedProgramId: null,
      duration: 60, // 单位分钟
      isCustomMaterial: false, // 指定节目素材
      selectedMaterialArray: [], // 选中的节目素材
      tempSelectionArray: [], // 临时存储数据
      dataColumns: [
        { type: 'selection', width: 60, align: 'center' },
        { title: '视频名称', key: 'fileName' },
        { title: '时长(秒)', key: 'duration', align: 'center' },
        {
          title: '预览',
          align: 'center',
          width: 100,
          render: (h, params) => {
            return h('div', [
              h('Icon', {
                props: { type: 'md-skip-forward', size: 18, color: '#19be6b' },
                style: { 'margin-left': '8px', cursor: 'pointer' },
                on: {
                  click: () => {
                    this.$refs.videoShow.show(params.row)
                  }
                }
              })
            ]
            )
          }
        }
      ]
    }
  },
  methods: {
    initShow () {
      this.duration = this.operationType === 1 ? 60 : this.duration
      this.isCustomMaterial = false
      this.tempSelectionArray = []
      this.selectedMaterialArray = []
      this.setDurationModal = true

      this.getContentMaterialData()
    },
    changeIsCustomMaterial () {
      this.selectedMaterialArray = !this.isCustomMaterial ? [] : this.selectedMaterialArray
    },
    handleSelection (selection) {
      this.selectedMaterialArray = []

      const curSelection = selection.map(x => {
        return { id: x.id, fileName: x.fileName, duration: x.duration }
      })
      this.handelPageSelections(this.tempSelectionArray, curSelection, this.materialQuery.pageNum)
      this.tempSelectionArray.forEach(item => {
        this.selectedMaterialArray = this.selectedMaterialArray.concat(item.existSelections)
      })
    },
    handleConfirm () {
      this.$Modal.confirm({
        title: '操作提示',
        content: '确认信息无误，并提交？',
        onOk: () => {
          const postData = {
            programId: this.selectedProgramId,
            duration: this.duration * 60 // 将分钟转成秒
          }
          if (this.operationType === 1) {
            const fillContentIds = this.selectedMaterialArray.map(x => x.id)
            const newData = Object.assign(postData, { fillContentIds: fillContentIds.toString() })
            // 初始化生成
            createProgramDetails(newData).then(res => {
              if (res && res.errcode === 0) {
                this.$Notice.success({ desc: '操作成功' })
                this.successFun()
              }
              this.setDurationModal = false
            })
          } else {
            // 重新生成
            recreateProgramDetails(postData).then(res => {
              if (res && res.errcode === 0) {
                this.$Notice.success({ desc: '操作成功' })
                this.successFun()
              }
              this.setDurationModal = false
            })
          }
        }
      })
    }
  },
  watch: {
    programId (val) {
      this.selectedProgramId = val
    },
    sumDuration (val) {
      this.duration = val * 1 > 0 ? parseInt(val / 60) : 0
    },
    videolist: {
      deep: true,
      immediate: true,
      handler (val) {
        val.forEach(item => {
          item._checked = this.selectedMaterialArray.some(x => x.id === item.id)
        })
      }
    }
  }
}
</script>
